<template>
  <div
    ref="b-select-checkbox"
    class="dropdown noselect"
    style="margin-right: 20px"
    tabindex="1"
    :class="{ disabled }"
  >
    <div
      :id="componentId"
      class="select"
      :class="[showDropdown ? 'open' : 'closed']"
      @click="showDropdown = !showDropdown"
    >
      <div class="selected-opt-wrapper">
        <span class="selected-opt-header">{{ header ? `${header}: ` : '' }}</span
        ><span class="selected-opt">{{ selected }}</span>
      </div>
      <font-awesome-icon
        :icon="['far', showDropdown ? 'chevron-up' : 'chevron-down']"
        class="droparw"
      />
    </div>
    <ul v-if="showDropdown" :id="dropdownId" class="dropdown-menu">
      <li v-if="showAll && multiSelectEnabled" class="norm-option" @click="selectAll">
        <font-awesome-icon
          v-if="multiSelectEnabled && iconPos === 'left'"
          class="check left"
          :class="{ 'check-off': !allSelected, wl: applyWhiteLabeling }"
          :icon="['fa', 'check']"
          size="6x"
        />
        <span class="norm-option-item">{{ customSelectAllText }}</span>
        <font-awesome-icon
          v-if="multiSelectEnabled && iconPos === 'right'"
          class="check"
          :class="{ 'check-off': !allSelected, wl: applyWhiteLabeling }"
          :icon="['fa', 'check']"
          size="6x"
        />
      </li>
      <li
        v-for="(option, i) in optionsCopy"
        :key="i"
        :class="[
          option.showStar ? 'rollup-option' : 'norm-option',
          option.disabled ? 'disabled' : '',
        ]"
        @click="toggleOption(option, i)"
        @mouseover="(el) => handleMouseOver(el, i + 1, option.value)"
        @mouseleave="handleMouseLeave"
      >
        <div v-if="option.showStar" class="roll-preset">
          <font-awesome-icon :icon="['fas', 'star']" class="check-on checkbox-active" />
        </div>
        <font-awesome-icon
          v-if="multiSelectEnabled && iconPos === 'left'"
          class="check left"
          :class="{ 'check-off': !option.checked, wl: applyWhiteLabeling }"
          :icon="['fa', 'check']"
          size="6x"
        />
        <span class="norm-option-item">{{ option.value }}</span>
        <font-awesome-icon
          v-if="multiSelectEnabled && iconPos === 'right'"
          class="check"
          :class="{ 'check-off': !option.checked, wl: applyWhiteLabeling }"
          :icon="['fa', 'check']"
          size="6x"
        />
      </li>
    </ul>
    <div v-if="!disableToolTip" :style="activeEl.style" class="tooltipText">
      {{ activeEl.text }}
    </div>
  </div>
</template>

<script>
import { uuid } from '~/helpers/global/misc-helpers';

export default {
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    showAll: {
      type: Boolean,
      required: false,
      default: true,
    },
    header: {
      type: String,
      required: false,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconPos: {
      type: String,
      required: false,
      default: () => 'right',
    },
    componentId: {
      type: String,
      required: false,
      default: () => 'dropdownComponent',
    },
    applyWhiteLabeling: {
      type: Boolean,
      required: false,
      default: false,
    },
    customSelectAllText: {
      type: String,
      required: false,
      default: 'SELECT ALL',
    },
    multiSelectEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    disableToolTip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDropdown: false,
      optionsCopy: JSON.parse(JSON.stringify(this.options)) || [],
      activeEl: { style: { display: 'none' }, text: '' },
      dropdownId: uuid(),
    };
  },
  computed: {
    selected() {
      let result = '';
      if (this.allSelected && this.showAll) {
        result = 'ALL';
      } else {
        result = this.optionsCopy
          .filter((f) => f?.checked)
          .map((m) => m.value)
          .join(', ');
      }
      return result;
    },
    allSelected() {
      let result = true;
      this.optionsCopy.forEach((option) => {
        if (option.key !== 'ep') {
          if (!option?.checked && !option.disabled) {
            result = false;
          }
        }
      });

      return result;
    },
  },
  watch: {
    options: {
      deep: true,
      handler(n) {
        this.optionsCopy = JSON.parse(JSON.stringify(n));
      },
    },
    disabled(newVal, oldVal) {
      if (newVal !== oldVal && newVal && this.showDropdown) {
        this.showDropdown = false;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    onClickOutside(e) {
      if (this.showDropdown && !this.$el.contains(e.target)) {
        this.showDropdown = false;
        this.$emit('dropdown-closed');
      }
    },
    closeDropdown() {
      this.showDropdown = false;
      this.activeEl = { style: { display: 'none' }, text: '' };
    },
    handleMouseOver(el, i, text) {
      const ulElement = document.getElementById(this.dropdownId);
      if (!text) {
        return;
      }

      // Calculate the position based on the height of each list item
      const listItemHeight = 39.2; // Adjust this value based on your list item height
      const topOffset = this.multiSelectEnabled ? 80 : 30;
      const tooltipTop = i * listItemHeight - ulElement.scrollTop + topOffset;

      this.activeEl = {
        style: {
          top: `${tooltipTop}px`,
          left: `${25}px`,
          display: 'block',
        },
        text,
      };
    },

    handleMouseLeave() {
      this.activeEl = { style: { display: 'none' }, text: '' };
    },
    selectAll() {
      if (this.multiSelectEnabled) {
        const selectedOptions = JSON.parse(JSON.stringify(this.optionsCopy));
        selectedOptions.forEach((option) => {
          if (option.disabled) {
            return;
          }
          option.checked = !this.allSelected;
        });
        this.optionsCopy = selectedOptions;
        this.$emit('selected-options', this.optionsCopy, true);
      }
    },
    toggleOption(option, index) {
      if (!this.multiSelectEnabled) {
        this.optionsCopy.forEach((opt, i) => {
          if (i === index) {
            opt.checked = true;
          } else {
            opt.checked = false;
          }
        });
        this.showDropdown = false;
        this.handleMouseLeave();
        this.$emit('selected-options', this.optionsCopy, false, index);
      } else {
        option.checked = !option.checked;
        this.handleMouseLeave();
        this.$emit('selected-options', this.optionsCopy, false, index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.light-theme {
  .selected-opt-header {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707e8a !important;
  }
  .selected-opt {
    color: #212325 !important;
  }
  .check {
    color: #5b6165;
    box-shadow: 0px 0px 2px #484848;
    background: #ffffff;
  }
  ul li svg {
    color: #212325;
    fill: #212325;
    &.check-off {
      color: transparent !important;
    }
  }
}
.check {
  width: 1em;
  padding: 2px;
  color: var(--defaultprimarycolor);
  border-radius: 30px;
  box-shadow: inset 0px 0px 1px 1px #2aa4af70;
  &.left {
    margin-right: 0.5rem;
  }
  &.wl {
    color: var(--primarycolor) !important;
    &.check-off {
      color: transparent !important;
    }
  }
}
ul li svg {
  color: #212325;
  fill: #212325;
  &.check-off {
    color: transparent !important;
  }
}
.check-off {
  color: transparent !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.selected-opt-header {
  font-size: 14px;
  color: #707e8a;
}
.selected-opt-wrapper {
  display: inline-flex;
  width: 90%;
  white-space: nowrap;
}
.selected-opt {
  display: inline-block;
  width: 90%;
  margin-left: 0.2rem;
  overflow: hidden;
  font-size: 14px;
  color: #cad1d6;
  text-overflow: ellipsis;
  white-space: nowrap;
}
svg.reverse {
  transform: rotate(180deg);
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}
svg {
  float: right;
}
ul li svg {
  position: relative;
  font-size: 18px;
  color: #cbccd2;
  border: none;
  fill: #cbccd2;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .tooltipText {
    position: absolute;
    z-index: 999;
    width: auto;
    font-size: 12px;
    color: #222;
    pointer-events: none;
    background-color: white; /* Set background color to white */
    text-align: center;
    border-radius: 3px;
    transition: ease 0.3s;
    line-height: 1.2em;
    transform-origin: bottom center;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increase shadow effect */
    margin-top: 5px; /* Adjust margin to make it appear slightly outside of the parent component */
  }
  * {
    outline: 0;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    min-width: 210px;
    height: 100%;
    font-size: 14px;
    color: #fff;
    text-align: left;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    border-radius: 4px;
    transition: all 0.3s ease;
    -webkit-touch-callout: none;
  }
  .light-theme {
    .selected-opt {
      color: #212325;
    }
    .dropdown {
      .dropdown-menu {
        background-color: #f9f9f9 !important;
        li {
          color: #555 !important;
          font-size: 13px !important;
          align-items: center !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.04) !important;
          &:hover {
            background-color: rgba(0, 0, 0, 0.04) !important;
          }
        }
      }
      .select {
        background-color: #ffffff !important;
        border: 1px solid #c5d2d8 !important;
        color: #555 !important;
        &.open {
          background-color: #ffffff;
        }
      }
      .select.open .droparw {
        color: inherit !important;
      }
    }
  }
  .dropdown .select {
    display: block;
    min-height: 40px;
    padding: 8px 10px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid rgba(170, 170, 170, 0.1);
    border-radius: 4px;
    &.open {
      background-color: #383a44;
    }
  }
  .droparw {
    position: relative;
    top: 5px;
    float: right;
    font-size: 11px;
    line-height: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .dropdown .dropdown-menu {
    position: absolute;
    left: 0;
    z-index: 9;
    width: 100%;
    max-height: 250px;
    margin-top: 0;
    overflow: hidden;
    overflow-y: auto;
    background-color: rgb(45, 46, 53, 0.98);
    border-radius: 0 1px 4px 4px;
    box-shadow: 0 5px 3px 1px rgba(0, 0, 0, 0.1);
  }
  .dropdown .dropdown-menu li {
    padding: 10px;
    font-size: 13px;
    color: #cad1d6;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    svg {
      font-size: 17px;
    }
  }
  .dropdown .dropdown-menu li.norm-option {
    display: flex;

    .norm-option-item {
      width: 89%;
      padding-right: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .dropdown .dropdown-menu {
    padding: 0;
    list-style: none;
  }
  .dropdown .dropdown-menu li:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .dropdown .dropdown-menu li:active {
    background-color: #333;
  }
  .roll-preset {
    display: inline-block;
    padding: 0 4px;
    margin-right: 6px;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    background-color: var(--defaultprimarycolor);
    border-radius: 12px;
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}
</style>
